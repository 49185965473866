
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-article-tray {
        &__header {
            margin-bottom: $nu-spacer-4;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-5;
            }
        }

        &__articles {
            margin-bottom: $nu-spacer-4;

            @include breakpoint(large) {
                margin-bottom: $nu-spacer-6;
            }
        }

        &__card {
            margin-bottom: $nu-spacer-2;

            @include breakpoint(large) {
                margin-bottom: 0;
            }
        }
    }

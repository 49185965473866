
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-social-sharing {
        text-align: center;

        &__links {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
        }

        &__link {
            cursor: pointer;
            padding: $nu-spacer-1;
        }
    }


                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-news-detail {
        &__article-header {
            padding-top: $nu-spacer-6;

            @include breakpoint(medium) {
                padding-top: $nu-spacer-12;
            }
        }

        &__separator {
            color: $nu-gray--medium;
            margin: 0 $nu-spacer-0pt5;
        }
    }


                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-article-card {
        background-color: $nu-white;
        color: $nu-black;
        width: 100%;
        height: 100%;

        &--featured {
            @include breakpoint(medium) {
               display: flex;
            }
        }

        &__image-container {
            &--featured {
                @include breakpoint(medium) {
                    width: 60%;
                    margin: auto;
                }
            }
        }

        &__content-container {
            padding: $nu-spacer-3;

            @include breakpoint(medium) {
                padding: $nu-spacer-4;
            }

            &--featured {
                @include breakpoint(medium) {
                    width: 40%;
                    margin: auto;
                }
            }
        }

        &__separator {
            color: $nu-gray--medium;
            margin: 0 $nu-spacer-0pt5;
        }

        &__img {
            display: block;
            width: 100%;
        }
    }
